import React, { Suspense } from 'react';
import News from '../News/News';
import Activity from './Activity';
import LoadingSpinner from '../Common/LoadingSpinner'

const InfoDisplay = () => {
  return (

    <div className="container mx-auto px-4 sm:px-6 lg:px-12 mt-8 sm:mt-16 lg:mt-32 pb-12 sm:pb-16 lg:pb-32 h-full-screen">
      <div className="flex flex-col xl:flex-row xl:space-x-8 space-y-8 xl:space-y-0">
        <div className="w-full xl:w-1/3 rounded-lg overflow-hidden">
          <News />
        </div>
        <div className="w-full xl:w-2/3 rounded-lg overflow-hidden">
          <Suspense fallback={<LoadingSpinner />}>
            <Activity />
          </Suspense>
        </div>
      </div>
    </div>
  );
};

export default InfoDisplay;
