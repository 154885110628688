// src/pages/Landing.js

import React, { Suspense, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import FeaturesDisplay from '../Components/Landing/FeaturesDisplay';
import InfoDisplay from '../Components/Landing/InfoDisplay';
import { fetchLandingPageCompanies } from '../Components/Companies/companiesSlice';
import LoadingSpinner from '../Components/Common/LoadingSpinner'

const CompanyItem = React.lazy(() => import('../Components/Companies/CompanyItem'));

const Landing = () => {
  const dispatch = useDispatch();

  const {
    landingPageCompanies,
    loadingLandingPageCompanies,
    errorLandingPageCompanies,
    cachedLandingPageCompanies
  } = useSelector((state) => state.companies);

  useEffect(() => {
    if (!cachedLandingPageCompanies) {
      dispatch(fetchLandingPageCompanies());
    }
  }, [dispatch, cachedLandingPageCompanies]);

  return (
    <div className="relative bg-black min-h-screen">
      <main>
        <FeaturesDisplay />

        <section className="my-16">
          <h2 className="text-white text-center text-3xl font-bold mb-8">业界好评</h2>

          <Suspense fallback={<LoadingSpinner />}>
            <CompanyItem
              className="text-white"
              companyLevel="LandingCompany"
              companies={landingPageCompanies}
              loadingCompanies={loadingLandingPageCompanies}
              errorCompanies={errorLandingPageCompanies}
              currentPage={1}
            />
          </Suspense>
        </section>

        <InfoDisplay />
      </main>
    </div>
  );
};

export default Landing;
