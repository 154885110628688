import React, { useState } from 'react';

const PostEvent = ({ addEvent }) => {
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [date, setDate] = useState('');
    const [time, setTime] = useState('');
    const [cost, setCost] = useState('');
    const [organizer, setOrganizer] = useState('');
    const [phone, setPhone] = useState('');
    const [location, setLocation] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        const newEvent = {
            id: Date.now(), // unique ID for the event
            title,
            description,
            date,
            time,
            cost,
            organizer,
            phone,
            location,
            comments: [],
            timestamp: new Date().toLocaleString(), // Add the event timestamp
        };
        addEvent(newEvent);
        // Reset form fields
        setTitle('');
        setDescription('');
        setDate('');
        setTime('');
        setCost('');
        setOrganizer('');
        setPhone('');
        setLocation('');
    };

    return (
        <div className="p-4 bg-white shadow-md rounded-lg">
            <h2 className="text-2xl font-bold mb-4">发布活动</h2>
            <form onSubmit={handleSubmit}>
                <div className="mb-4">
                    <label className="p-2 border-gray-300 rounded text-lg w-4/5">活动名称：</label>
                    <input
                        id="title"
                        type="text"
                        className="w-full p-2 border border-gray-300 rounded"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        required
                    />
                </div>
                <div className="mb-4">
                    <label className="p-2 border-gray-300 rounded text-lg w-4/5">活动详情：</label>
                    <textarea
                        id="description"
                        className="w-full p-2 border border-gray-300 rounded"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        required
                    />
                </div>
                <div className="mb-4">
                    <label className="p-2 border-gray-300 rounded text-lg w-4/5">日期：</label>
                    <input
                        id="date"
                        type="date"
                        className="w-full p-2 border border-gray-300 rounded"
                        value={date}
                        onChange={(e) => setDate(e.target.value)}
                        required
                    />
                </div>
                <div className="mb-4">
                    <label className="p-2 border-gray-300 rounded text-lg w-4/5">时间：</label>
                    <input
                        id="time"
                        type="time"
                        className="w-full p-2 border border-gray-300 rounded"
                        value={time}
                        onChange={(e) => setTime(e.target.value)}
                        required
                    />
                </div>
                <div className="mb-4">
                    <label className="p-2 border-gray-300 rounded text-lg w-4/5">费用：</label>
                    <input
                        id="cost"
                        type="text"
                        className="w-full p-2 border border-gray-300 rounded"
                        value={cost}
                        onChange={(e) => setCost(e.target.value)}
                        required
                    />
                </div>
                <div className="mb-4">
                    <label className="p-2 border-gray-300 rounded text-lg w-4/5">主办方：</label>
                    <input
                        id="organizer"
                        type="text"
                        className="w-full p-2 border border-gray-300 rounded"
                        value={organizer}
                        onChange={(e) => setOrganizer(e.target.value)}
                        required
                    />
                </div>
                <div className="mb-4">
                    <label className="p-2 border-gray-300 rounded text-lg w-4/5">电话：</label>
                    <input
                        id="phone"
                        type="text"
                        className="w-full p-2 border border-gray-300 rounded"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        required
                    />
                </div>
                <div className="mb-4">
                    <label className="p-2 border-gray-300 rounded text-lg w-4/5">地点：</label>
                    <input
                        id="location"
                        type="text"
                        className="w-full p-2 border border-gray-300 rounded"
                        value={location}
                        onChange={(e) => setLocation(e.target.value)}
                        required
                    />
                </div>
                <div className="flex justify-center mt-4">
                    <button type="submit" className="bg-[#f7cc11] text-black px-4 py-2 rounded justify-center">
                        发布
                    </button>
                </div>
            </form>
        </div>
    );
};

export default PostEvent;
