import React, { useState, useRef, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import logo from '../../Assets/logo.png';
import { FaSearch, FaBars, FaArrowRight } from 'react-icons/fa';
import { MdHelpOutline } from 'react-icons/md';
import SearchResults from './SearchResults';

const Navbar = () => {
  const [showSearch, setShowSearch] = useState(false);
  const [showIndustryDropdown, setShowIndustryDropdown] = useState(false);
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [showMobileIndustryDropdown, setShowMobileIndustryDropdown] = useState(false);
  const [searchResults, setSearchResults] = useState([]); // 用于存储搜索结果
  const [notFound, setNotFound] = useState(false); // 是否搜索到相关公司
  const [searchPerformed, setSearchPerformed] = useState(false); // 是否已经搜索过

  const searchRef = useRef();
  const searchInputRef = useRef();
  const industryTextRef = useRef();
  const industryDropdownRef = useRef();
  const dropdownTimeout = useRef(null);
  const showDropdownTimer = useRef(null);

  const navigate = useNavigate();
  const location = useLocation();

  const industries = [
    { name: '全部', path: '/companies' },
    { name: '地产开发', path: '/companies/realestate' },
    { name: '建筑设计', path: '/companies/design' },
    { name: '房屋建造', path: '/companies/builder' },
    { name: '建筑材料', path: '/companies/material' },
    { name: '室内装修', path: '/companies/decoration' },
    { name: '房产经纪', path: '/companies/agency' },
    { name: '房屋信贷', path: '/companies/mortgage' },
    { name: '法律咨询', path: '/companies/law' },
    { name: '会计事务', path: '/companies/accounting' }
  ];

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setShowSearch(false);
        setSearchResults([]); 
      }
      if (!industryTextRef.current?.contains(event.target) && !industryDropdownRef.current?.contains(event.target)) {
        handleCloseDropdown();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleIndustryTextEnter = () => {
    clearTimeout(dropdownTimeout.current);
    showDropdownTimer.current = setTimeout(() => {
      setShowIndustryDropdown(true);
    }, 300);
  };

  const handleIndustryTextLeave = () => {
    clearTimeout(showDropdownTimer.current);
    handleCloseDropdown();
  };

  const handleDropdownEnter = () => {
    clearTimeout(dropdownTimeout.current);
  };

  const handleDropdownLeave = () => {
    handleCloseDropdown();
  };

  const handleCloseDropdown = () => {
    clearTimeout(showDropdownTimer.current);
    dropdownTimeout.current = setTimeout(() => {
      setShowIndustryDropdown(false);
    }, 200);
  };

  const handleMobileMenuToggle = () => {
    setShowMobileMenu(!showMobileMenu);
    setShowMobileIndustryDropdown(false);
  };

  const handleMobileIndustryClick = () => {
    setShowMobileIndustryDropdown(!showMobileIndustryDropdown);
  };

  const handleMobileIndustryItemClick = (path) => {
    navigate(path);
    setShowMobileMenu(false);
    setShowMobileIndustryDropdown(false);
  };

  const handleLinkClick = (path) => {
    setShowIndustryDropdown(false);
    navigate(path);
  };

  const isActive = (path) => {
    if (path === '/') {
      return location.pathname === '/';
    }
    if (path === '/companies') {
      return location.pathname === '/companies' || industries.some(industry => location.pathname === industry.path);
    }
    return location.pathname === path;
  };

  // 搜索的api处理函数
  const handleSearch = async () => {
    const query = searchInputRef.current.value.trim(); // 去除前后空格
    setSearchPerformed(true);
    setNotFound(false);

    if (query) {
      try {
        const baseUrl = process.env.REACT_APP_API_URL || 'http://localhost:4000';
        const response = await fetch(`${baseUrl}/company/search?query=${query}`);

        if (!response.ok) {
          if (response.status === 404) {
            console.log('搜索失败: 没有找到匹配的公司');
            setSearchResults([]); // 保存空数据
            setNotFound(true);
            return;
          }
          throw new Error(`请求失败，状态码: ${response.status}`);
        }

        const data = await response.json();
        setSearchResults(data); // 将结果保存到状态中
      } catch (error) {
        console.error('搜索失败:', error);
      }
    }
  };

  // 点击公司后关闭搜索框
  const handleSelect = () => {
    setShowSearch(false); // 点击结果后关闭搜索框
    setSearchResults([]); // 清空搜索结果
    setSearchPerformed(false);
  };

  return (
    <div className="fixed top-0 left-0 w-full text-white shadow-lg z-50 bg-gray-black py-2 font-sans text-gray-200">
      {showIndustryDropdown && (
        <div className="fixed inset-0 bg-black bg-opacity-30 backdrop-blur-md z-40 transition-opacity duration-300"></div>
      )}
      <div className="flex items-center justify-between px-2 md:px-4 lg:px-6 relative z-50">
      <Link to="/" className="flex items-center gap-2" onClick={() => setShowMobileMenu(false)}>
        <img src={logo} alt="logo" className="h-10" />
        <span className="text-sm lg:text-base font-medium text-white">企信网</span>
      </Link>

        <div className={`hidden md:flex items-center ${showSearch ? 'hidden' : ''}`}>
          <ul className="flex pr-20 space-x-5 text-sm md:text-base lg:text-md font-medium">
            <li>
              <Link to="/" className="hover:text-gray-400 transition-colors duration-200">首页</Link>
              {isActive('/') && <hr className="w-1/2 h-0.5 bg-yellow border-0 mx-auto" />}
            </li>
            <li className="relative group">
              <div
                ref={industryTextRef}
                className="px-4 py-2 -mx-4 -my-2"
                onMouseEnter={handleIndustryTextEnter}
                onMouseLeave={handleIndustryTextLeave}
              >
                <span className="cursor-pointer hover:text-gray-400 transition-colors duration-200">公司探索</span>
                {isActive('/companies') && <hr className="w-1/2 h-0.5 bg-yellow border-0 mx-auto" />}
              </div>

              {!isMobile && (
                <div
                  ref={industryDropdownRef}
                  className={`absolute left-1/2 transform -translate-x-1/2 mt-2 bg-gray-black rounded-lg shadow-lg py-1 z-50 transition-all duration-500 ease-out ${showIndustryDropdown ? 'opacity-100 visible translate-y-0' : 'opacity-0 invisible -translate-y-4'}`}
                  style={{ width: '400px' }}
                  onMouseEnter={handleDropdownEnter}
                  onMouseLeave={handleDropdownLeave}
                >
                  <div className="grid grid-cols-3 gap-2 p-4">
                    <span
                      onClick={() => handleLinkClick('/companies')}
                      className="col-span-3 text-center px-4 py-2 text-sm text-gray-100 hover:bg-yellow rounded-full hover:text-white hover:rounded-full transition-colors duration-200 cursor-pointer"
                    >
                      全部
                    </span>
                    {industries.slice(1).map((industry) => (
                      <span
                        key={industry.name}
                        onClick={() => handleLinkClick(industry.path)}
                        className="text-center px-4 py-2 text-sm text-gray-100 hover:bg-yellow rounded-full hover:text-white hover:rounded-full transition-colors duration-200 cursor-pointer"
                      >
                        {industry.name}
                      </span>
                    ))}
                  </div>
                </div>
              )}
            </li>
            <li>
              <Link to="/calendar" className="hover:text-gray-400 transition-colors duration-200">活动日历</Link>
              {isActive('/calendar') && <hr className="w-1/2 h-0.5 bg-yellow border-0 mx-auto" />}
            </li>
            <li>
              <Link to="/contact" className="hover:text-gray-400 transition-colors duration-200">关于企信</Link>
              {isActive('/contact') && <hr className="w-1/2 h-0.5 bg-yellow border-0 mx-auto" />}
            </li>
          </ul>
        </div>

        <div className="flex items-center">
          <MdHelpOutline
            className="cursor-pointer mr-4"
            onClick={() => navigate('/QA')}
            size={28}
          />
          <FaSearch
            className="cursor-pointer mr-4"
            onClick={() => {
              setShowSearch(true);
              setSearchResults([]); // 点击搜索图标时清空搜索结果
            }}
            size={20}
          />
          {isMobile && (
            <FaBars
              className="md:hidden cursor-pointer"
              onClick={handleMobileMenuToggle}
            />
          )}
        </div>

        {showSearch && (
          <div className="fixed inset-0 flex items-start justify-center z-50">
            <div
              className="absolute inset-0 bg-black bg-opacity-30 backdrop-blur-md z-40"
              onClick={() => setShowSearch(false)}
            ></div>
            <div
              className="relative z-50 flex flex-col items-center w-full max-w-lg" // 最大宽度可根据需要调整
              onClick={(e) => e.stopPropagation()}
            >
              <div className="flex px-8 md:px-0 w-full mb-2 items-center mt-4">
                <input
                  ref={searchInputRef}
                  type="text"
                  className="flex-1 px-6 p-2 rounded-full focus:outline-none bg-white text-black text-sm md:text-base lg:text-lg"
                  placeholder="搜索..."
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      handleSearch(); // 按下回车键搜索
                    }
                  }}
                />
                <FaArrowRight
                  className="absolute right-10 md:right-5 h-4 w-4 md:h-5 md:w-5 cursor-pointer text-gray-500 hover:text-gray-700"
                  onClick={handleSearch} // 点击箭头搜索
                />
              </div>

              {/* 渲染搜索结果 */}
              {searchPerformed && (
                <SearchResults 
                  results={searchResults} 
                  onSelect={handleSelect} 
                  notFound={notFound}
                />
              )}
            </div>
          </div>
        )}


      </div>

      {isMobile && (
        <div
          className={`md:hidden bg-gray-black absolute top-full left-0 w-full z-50 overflow-hidden transition-all duration-300 ease-in-out ${showMobileMenu ? 'max-h-screen' : 'max-h-0'}`}
        >
          <ul className="py-2">
            <li>
              <Link to="/" className="block px-4 py-2 hover:bg-yellow" onClick={() => setShowMobileMenu(false)}>首页</Link>
            </li>
            <li>
              <span className="block px-4 py-2 hover:bg-yellow cursor-pointer" onClick={handleMobileIndustryClick}>公司探索</span>
              <div className={`overflow-hidden transition-all duration-300 ease-in-out ${showMobileIndustryDropdown ? 'max-h-screen' : 'max-h-0'}`}>
                <ul className="pl-8 bg-gray-black">
                  {industries.map((industry) => (
                    <li key={industry.name}>
                      <span
                        className="block px-4 py-2 text-xs hover:bg-yellow cursor-pointer"
                        onClick={() => handleMobileIndustryItemClick(industry.path)}
                      >
                        {industry.name}
                      </span>
                    </li>
                  ))}
                </ul>
              </div>
            </li>
            <li>
              <Link to="/calendar" className="block px-4 py-2 hover:bg-yellow" onClick={() => setShowMobileMenu(false)}>活动日历</Link>
            </li>
            <li>
              <Link to="/contact" className="block px-4 py-2 hover:bg-yellow" onClick={() => setShowMobileMenu(false)}>关于企信</Link>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default Navbar;
