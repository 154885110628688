import React, { useState, useEffect } from 'react';
import WenMortgage from '../../Assets/Wen_Mortgage.jpg';
import FeatureBox from './FeatureBox';
import FeatureBar from '../Common/FeatureBar';
import LivecomfyTop from '../../Assets/Livecomfy_Top.png';
import Mortgage from '../../Assets/mortgage';
import Realestate from '../../Assets/realestate';
import Builder from '../../Assets/builder';
import Design from '../../Assets/design';
import Material from '../../Assets/material';
import Agency from '../../Assets/agency';
import Decoration from '../../Assets/decoration';
import Law from '../../Assets/law';
import Accounting from '../../Assets/accounting';

const FeaturesDisplay = () => {
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    setAnimate(true);
  }, []);

  return (
    <div className="w-full min-h-full bg-black py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto space-y-8 sm:space-y-16">
        {/* First FeatureBar */}
        <div className="overflow-hidden">
          <div className={`flex flex-col lg:flex-row items-center justify-between space-y-4 lg:space-y-0 lg:space-x-8 transition-all duration-1000 ease-out ${animate ? 'translate-x-0 opacity-100' : '-translate-x-full opacity-0'}`}>
            <div className="w-full lg:w-2/3">
              <FeatureBar imageUrl={WenMortgage} link="/company/349" />
            </div>
            <h2 className="text-xl sm:text-2xl lg:text-3xl font-semibold text-gray-100 w-full lg:w-1/3 text-center lg:text-left">
            Wen Mortgage，让梦想住房触手可及。
            </h2>
          </div>
        </div>

        {/* Second FeatureBar */}
        <div className="overflow-hidden">
          <div className={`flex flex-col lg:flex-row-reverse items-center justify-between space-y-4 lg:space-y-0 lg:space-x-8 transition-all duration-1000 ease-out ${animate ? 'translate-x-0 opacity-100' : 'translate-x-full opacity-0'}`}>
            <div className="w-full lg:w-2/3">
              <FeatureBar imageUrl={LivecomfyTop} link="/company/60" />
            </div>
            <h2 className="text-xl sm:text-2xl lg:text-3xl font-semibold text-gray-100 w-full lg:w-1/3 text-center lg:text-left">
              Livecomfy，堪培拉地产龙头企业。
            </h2>
          </div>
        </div>

        {/* FeatureBoxes */}
        <div className="flex flex-wrap justify-center sm:justify-start">
          <FeatureBox title="地产开发" to="companies/realestate" Icon={Realestate} />
          <FeatureBox title="建筑设计" to="companies/design" Icon={Design} />
          <FeatureBox title="房屋建造" to="companies/builder" Icon={Builder} />
          <FeatureBox title="建筑材料" to="companies/material" Icon={Material} />
          <FeatureBox title="房屋装修" to="companies/decoration" Icon={Decoration} />
          <FeatureBox title="房产经纪" to="companies/agency" Icon={Agency} />
          <FeatureBox title="房屋信贷" to="companies/mortgage" Icon={Mortgage} />
          <FeatureBox title="法律咨询" to="companies/law" Icon={Law} />
          <FeatureBox title="会计事务" to="companies/accounting" Icon={Accounting} />
        </div>
      </div>
      <hr className="w-5/6 mx-auto h-1 bg-yellow border-0 my-8 rounded-2xl mt-16 sm:mt-4 lg:mt-20 xl:mt-28" />
    </div>
  );
}

export default FeaturesDisplay;