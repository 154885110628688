import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import { Helmet } from 'react-helmet';
import './App.css';
import Footer from './Components/Common/Footer';
import Navbar from './Components/Common/Navbar';
import Landing from './Pages/Landing';
import CompaniesPage from './Pages/CompaniesPage';
import CompanyCalendar from './Pages/CompanyCalendar';
import ContactPage from './Pages/ContactPage';
import SingleCompanyPage from './Pages/SingleCompanyPage';
import EventLandingPage from './Pages/EventLandingPage';
import NewsLandingPage from './Pages/NewsLandingPage';
import NewsDetailPage from './Pages/NewsDetailPage';
import QAPage from './Pages/QAPage';
import CompanyCRM from './Pages/CompanyCRM';
import DetailedQAPage from './Pages/DetailedQAPage';

// ScrollToTop component
const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

function App() {
  return (
    <div>
      <Helmet>
        <title>企信网 - Qixin | 您的澳洲房产合作伙伴</title>
        <link rel="icon" href="/favicon.ico" type="image/x-icon" />
        <link rel="icon" href="/favicon-16x16.png" sizes="16x16" type="image/png" />
        <link rel="icon" href="/favicon-32x32.png" sizes="32x32" type="image/png" />
      </Helmet>
      <Router>
        <ScrollToTop />
        <Navbar />
        <div className="pt-14"> {/* Adjust this padding based on your navbar height */}
          <Routes>
            <Route path="/" element={<Landing />} />
            <Route path="/companies" element={<CompaniesPage />} />
            <Route path="/company" element={<CompaniesPage />} />
            <Route path="/companies/:industry" element={<CompaniesPage />} />
            <Route path="/company/:id" element={<SingleCompanyPage />} />
            <Route path="/calendar" element={<CompanyCalendar />} />
            <Route path="/calendar/:id" element={<EventLandingPage />} />
            <Route path="/news" element={<NewsLandingPage />} />
            <Route path="/news/:id" element={<NewsDetailPage />} />
            <Route path='/contact' element={<ContactPage />} />
            <Route path='/QA' element={<QAPage />} />
            <Route path="/QA/:id" element={<DetailedQAPage />} />
            <Route path='/companycrm' element={<CompanyCRM />} />
          </Routes>
        </div>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
