export const formatNewsData = (news) => {
  const date = new Date(news.Date);

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');

  const formattedDate = `${year}-${month}-${day}`;

  return {
    id: news.news_id,
    title: news.Title,
    content: news.Content,
    date: formattedDate, // Use the formatted date here
  };
};
