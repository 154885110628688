import React, { useState, useEffect } from 'react';
import SidebarNav from '../Components/CRM/SidebarNav';
import AccountInfo from '../Components/CRM/AccountInfo';
import BreadcrumbHeader from '../Components/CRM/BreadcrumbHeader';
import Message from '../Components/CRM/CompanyMessage'
import EventList from '../Components/CRM/EventList';
import HomepageModification from '../Components/CRM/EditHome';

const CompanyCRM = () => {
    const [activeTab, setActiveTab] = useState('account');
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    const [showMobileMenu, setShowMobileMenu] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleMobileMenuToggle = () => {
        setShowMobileMenu(!showMobileMenu);
    };

    const initialCompanyData = {
        mainBusiness: 'Software Development',
        companyProfile: 'We develop innovative software solutions.',
        companyIntroduction: 'Established in 2020, we focus on creating user-friendly applications.',
        products: [],
        images: [], // This could be an array of image URLs or files
    };

    const [companyData, setCompanyData] = useState(initialCompanyData);

    const updateCompany = (updatedData) => {
        // Update the company data state with new values
        setCompanyData((prevData) => ({
            ...prevData,
            mainBusiness: updatedData.mainBusiness,
            companyProfile: updatedData.companyProfile,
            companyIntroduction: updatedData.companyIntroduction,
            images: updatedData.images, // This can handle the image files
        }));
        // You can also handle additional logic here, such as sending updated data to an API
        console.log('Updated company data:', updatedData);
    };

    const renderContent = () => {
        switch (activeTab) {
            case 'account':
                return <AccountInfo />;
            case 'messages':
                return <Message />;
            case 'postEvent':
                return <EventList />;
            case 'editHomepage':
                return <HomepageModification companyData={companyData} updateCompany={updateCompany} />;
            case 'shopmall':
                return <></>;
            default:
                return <div>请选择一个功能</div>;
        }
    };

    return (
        <div className="flex md:w-3/5 sm:w-8/9 mx-auto font-sans shadow-lg mt-5">
            {(<SidebarNav onTabChange={setActiveTab} showMobileMenu={showMobileMenu} setShowMobileMenu={setShowMobileMenu} />)}
            <div className="flex-1 flex flex-col bg-gray-100">
                <BreadcrumbHeader
                    isMobile={isMobile}
                    handleMobileMenuToggle={handleMobileMenuToggle}
                    activeTab={activeTab}
                />

                {/* 主界面：账户信息或其他内容 */}
                <div className="p-6">
                    {renderContent()}
                </div>
            </div>
        </div>
    );
}

export default CompanyCRM
