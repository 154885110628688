import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchNewsById } from '../Components/News/newsSlice';
import { FaCalendarAlt, FaUser } from 'react-icons/fa';
import LivecomfyTop from '../Assets/Livecomfy_Top.png';
import FeatureBar from '../Components/Common/FeatureBar';
import Breadcrums from '../Components/Common/Breadcrums';
import WenMortgage from '../Assets/Wen_Mortgage.jpg';
import FormattedText from '../Components/Common/FormattedText';

const NewsDetailPage = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const { newsItem, loading, error } = useSelector((state) => state.news);

  const formatDate = (dateString) => {
    const [year, month, day] = dateString.split('-');
    return `${year}-${day}-${month}`;  // 手动调换月份和日期的位置
  };

  useEffect(() => {
    dispatch(fetchNewsById(id));
  }, [dispatch, id]);

  if (loading) {
    return <div className="text-center py-10">Loading...</div>;
  }

  if (error) {
    return <div className="text-center py-10 text-red-500">Error: {error}</div>;
  }

  if (!newsItem) {
    return <div className="text-center py-10">News item not found.</div>;
  }

  return (
    <div className="bg-gray-100 min-h-screen pt-4">
      <Breadcrums color="text-gray-700" additionalText="新闻详情" />


      <div className="max-w-4xl mx-auto px-4 py-8">
        {/* 新闻标题 */}
        <h1 className="text-3xl text-black font-bold mb-4 text-center">
          {newsItem.title}
        </h1>

        {/* 新闻日期 */}
        <div className="flex items-center text-gray-600  justify-center">
          <span className="mr-4 flex items-center">
            <FaCalendarAlt className="inline mr-1" />
            {formatDate(newsItem.date)}
          </span>
        </div>

        {/* 顶部广告位 */}
        <div className="w-full flex justify-center py-8">
          <div className="w-full flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4">
            <div className="w-full sm:w-1/2 aspect-[5/1]">
              <FeatureBar
                imageUrl={WenMortgage}
                link="/company/349"
              />
            </div>
            <div className="w-full sm:w-1/2 aspect-[5/1]">
              <FeatureBar
                imageUrl={LivecomfyTop}
                link="/company/60"
              />
            </div>
          </div>
        </div>

        {/* 新闻内容 */}
        <div className="bg-white p-6 rounded-lg shadow-md text-gray-700 leading-relaxed" style={{ minHeight: '400px' }}>
          <FormattedText text={newsItem.content} />
        </div>
      </div>
    </div>
  );
};

export default NewsDetailPage;