import React, { useState, useRef } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { IoMdAlarm } from "react-icons/io";

const EventCard = ({ event }) => {
  const [hovered, setHovered] = useState(false);
  const containerRef = useRef(null);
  const navigate = useNavigate();

  const handleCardClick = (e) => {
    // 防止点击链接时触发卡片的点击事件
    if (e.target.tagName !== 'A') {
      navigate(`/calendar/${event.id}`);
    }
  };

  return (
    <div
      ref={containerRef}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onClick={handleCardClick}
      className={`transition-transform transition-opacity duration-300 ease-out cursor-pointer ${
        hovered ? 'opacity-100 translate-y-0 shadow-lg' : 'opacity-100 translate-y-0 shadow-md'
      }`}
    >
      <div className="flex justify-between bg-white rounded-lg p-4 mb-4">
        <div className="w-1/4 bg-gray-800 text-white p-4 flex flex-col items-center justify-center rounded-l-lg">
          <div className="text-xl font-bold">{event.start.slice(-2)}日</div>
          <div className="text-sm">{event.start.slice(5,7)}月</div>
        </div>
        <div className="w-2/4 ml-4 flex-grow">
          <h3 className="text-base md:text-lg text-gray-800 font-semibold">{event.title}</h3>
          <div className="mt-2 flex items-center">
            <span className="text-darkyellow mr-2">
              <IoMdAlarm className="h-5 w-5" />
            </span>
            <span className="text-black px-2 py-1 rounded-full text-xs md:text-sm">
              {event.time}
            </span>
          </div>
        </div>
        <Link 
          to={`/calendar/${event.id}`} 
          className="w-1/4 mx-2 sm:mx-4 md:mx-6 lg:mx-8 text-center text-gray-600 flex justify-end items-center text-xs md:text-sm hover:text-yellow"
          onClick={(e) => e.stopPropagation()} // 防止触发卡片的点击事件
        >
          详情 →
        </Link>
      </div>
    </div>
  );
};

const EventLists = ({ events }) => {  
  return (
    <div className="m-full mx-4 sm:mx-8 md:mx-12 lg:mx-36 mb-12">
      <h1 className="text-3xl font-bold text-start text-yellow">活动列表</h1>
      <div>
        <h2 className="text-2xl font-semibold text-start">2024</h2>
        {events.map((event, index) => (
          <EventCard event={event} key={index} />
        ))}
      </div>
    </div>
  );
};


export default EventLists;