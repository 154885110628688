import React, { useState } from 'react';

const HomepageModification = ({ companyData, updateCompany }) => {
    const [mainBusiness, setMainBusiness] = useState(companyData.mainBusiness);
    const [companyProfile, setCompanyProfile] = useState(companyData.companyProfile);
    const [companyIntroduction, setCompanyIntroduction] = useState(companyData.companyIntroduction);
    const [products, setProducts] = useState(companyData.products);
    const [imageFiles, setImageFiles] = useState([]);

    const handleImageChange = (e) => {
        const files = Array.from(e.target.files);
        const validFiles = files.filter(file => file.type.startsWith('image/') && imageFiles.length + validFiles.length <= 5);
        setImageFiles(prev => [...prev, ...validFiles]);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const updatedCompanyData = {
            mainBusiness,
            companyProfile,
            companyIntroduction,
            products,
            images: imageFiles
        };
        updateCompany(updatedCompanyData);
    };

    return (
        <div className="p-4 bg-white rounded-lg shadow-md">
            <h2 className="text-2xl font-bold mb-4">修改主页</h2>
            <form onSubmit={handleSubmit}>
                <div className="mb-4">
                    <label className="block text-gray-700">Main Business</label>
                    <input
                        type="text"
                        className="w-full p-2 border border-gray-300 rounded"
                        value={mainBusiness}
                        onChange={(e) => setMainBusiness(e.target.value)}
                        required
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-gray-700">Company Profile</label>
                    <textarea
                        className="w-full p-2 border border-gray-300 rounded"
                        value={companyProfile}
                        onChange={(e) => setCompanyProfile(e.target.value)}
                        required
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-gray-700">Company Introduction</label>
                    <textarea
                        className="w-full p-2 border border-gray-300 rounded"
                        value={companyIntroduction}
                        onChange={(e) => setCompanyIntroduction(e.target.value)}
                        required
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-gray-700">Product Display (Max 5 images)</label>
                    <input
                        type="file"
                        accept="image/*"
                        multiple
                        onChange={handleImageChange}
                        className="p-2 border border-gray-300 rounded"
                    />
                    <div className="mt-2">
                        {imageFiles.map((file, index) => (
                            <div key={index} className="text-gray-600">{file.name}</div>
                        ))}
                    </div>
                </div>
                <div className="flex justify-center mt-4">
                    <button type="submit" className="bg-[#f7cc11] text-black px-4 py-2 rounded justify-center">
                        更新修改
                    </button>
                </div>
            </form>
        </div>
    );
};

export default HomepageModification;
